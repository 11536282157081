import React, { Component } from "react";
import Popup from "reactjs-popup";
import {Textfield, Spinner} from 'react-mdl';
import Axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
//import StyledPopup from './styledPopup.js';

//const API_PATH = 'http://stefan.local/google_calendar_service/calendar-service.php';
const API_PATH = 'https://platzbuchung.tckirrberg.de/calendar-service.php';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

class BookingItem extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.state = {
            email: null,
            pin: null,
            freiFlag: props.freiFlag,
            justBooked: false,
            formDisabled: false
        };
        this.handleEmailInput = this.handleEmailInput.bind(this);
        this.handlePinInput = this.handlePinInput.bind(this);
        this.bookEvent = this.bookEvent.bind(this);
        this.callSnackbar = props.serviceCb;
    }

    handleShowSnackbar = () => {
       this.setState({ isSnackbarActive: true });
    }
    handleTimeoutSnackbar = () => {
       this.setState({ isSnackbarActive: false });
    }

    handleEmailInput = (email) => {
        this.setState({
            email: email.target.value
        });
    }
    handlePinInput = (pin) => {
        this.setState({
            pin: pin.target.value
        });
    }

    bookEvent = () => {
      //prevent double click and add spinning wheel
      this.setState({
          formDisabled: true
      });
      //this.callSnackbar("success","testhier");
      Axios({
          method: 'post',
          url: API_PATH,
          headers: { 'content-type': 'application/json' },
          data: {
              action: "updateEvent",
              email: this.state.email,
              pin: this.state.pin,
              id: this.props.id,
              platz: this.props.platz
          }
      })
      .then(result => {
        console.log(result);
          if(result.data){
            this.setState({
                freiFlag: 0,
                justBooked: true,
                formDisabled: false
            });
            this.callSnackbar("success",result.data);
            console.log("succes end",result.data);
          }
      })
      .catch(error => {
        if(error.response && error.response.data){
          this.setState({ error: error.response.data })
          this.callSnackbar("error",error.response.data);
        }
        else{
          this.setState({ error: error.data })
          this.callSnackbar("error",error.data);
        }
        this.setState({
            formDisabled: false
        });
    });
    }

    handleChange(selectedItems) {
        //this.setState({ selectedItems });
    }
    render() {
        /*const { items, selectedItems } = this.state;
        let regions = this.props.regions;
        let newItems = [];
        let iterator = 0;
        for(let item in regions){
            newItems.push({
                id: iterator++,
                label: item
            });
        }*/

        const getPlace = () =>{
          switch(this.props.platz){
            case "1": return (<div className="platz platz1">Platz {this.props.platz}</div>)
            case "2": return (<div className="platz platz2">Platz {this.props.platz}</div>)
            case "3": return (<div className="platz platz3">Platz {this.props.platz}</div>)
            case "4": return (<div className="platz platz4">Platz {this.props.platz}</div>)
          }
        }

        const getFormSpinner = () =>{
          if(this.state.formDisabled === true){
            return (<Spinner className="formSpinner" singleColor />)
          }
          else{
            return (<></>)
          }
        }

        const getFormButton = () => {
          if(this.state.formDisabled === true){
            return (<Button style={{marginRight: '10px'}} className="buttonBuchen" variant="contained" disabled onClick={this.bookEvent}>Buchen</Button>)
          }
          else{
            return (<Button style={{marginRight: '10px'}} className="buttonBuchen" variant="contained" color="primary" onClick={this.bookEvent}>Buchen</Button>)
          }
        }

        const getFormEmail = () => {
          if(this.state.formDisabled === true){
            return (<Textfield
                disabled
                onChange={this.handleEmailInput}
                label="E-Mail .."
                floatingLabel
                style={{width: '75%'}}
                />)
          }
          else{
            return (<Textfield
                onChange={this.handleEmailInput}
                label="E-Mail .."
                floatingLabel
                style={{width: '75%'}}
                />)
          }
        }

        const getFormPin = () => {
          if(this.state.formDisabled === true){
            return (<Textfield
                disabled
                onChange={this.handlePinInput}
                label="PIN .."
                floatingLabel
                style={{width: '25%'}}
                />)
          }
          else{
            return (<Textfield
                onChange={this.handlePinInput}
                label="PIN .."
                floatingLabel
                style={{width: '25%'}}
                />)
          }
        }

        if(this.state.freiFlag === 1) {
          return (
            <Popup trigger={<ul className="bookingItem">
              <div className="startTime">{this.props.startTime} Uhr</div>
              <div className="endTime">bis {this.props.endTime} Uhr</div>
              {getPlace()}
              <div className="datum">{this.props.wt}, {this.props.datum}</div>
            </ul>} modal>
            {close => (
              <div className="modal">
                <a className="close" onClick={close}>
                  &times;
                </a>
                <div className="popupHeader">Platzbuchung</div>
                <div className="content">
                <div className="datum">{this.props.wt}, {this.props.datum}</div>
                <div className="time"><span class="starttime">{this.props.startTime} Uhr</span> &nbsp;&nbsp;bis {this.props.endTime} Uhr</div>
                {getPlace()}
                <div className="emailwrapper">
                {getFormEmail()}
                </div>
                <div className="pinwrapper">
                {getFormPin()}
                </div>
                </div>
                {getFormSpinner()}
                <div className="actions">
                {getFormButton()}
                <Button style={{marginLeft: '10px'}} className="buttonAbbruch" variant="contained" color="secondary" onClick={() => {
                  console.log("modal closed ");
                  close();
                }}>Abbruch</Button>
                </div>
              </div>
            )}
          </Popup>
          );
        }
        else if(this.state.justBooked === false){
          return (
              <ul className="bookingItem booked">
              <div className="startTime">{this.props.startTime} Uhr</div>
              <div className="endTime">bis {this.props.endTime} Uhr</div>
              {getPlace()}
              <div className="datum">{this.props.wt}, {this.props.datum}</div>
              </ul>
          );
        }
        else{
          return (
              <ul className="bookingItem booked">
              <div className="startTime">{this.props.startTime} Uhr</div>
              <div className="endTime">bis {this.props.endTime} Uhr</div>
              {getPlace()}
              <div className="datum">{this.props.wt}, {this.props.datum}</div>
              </ul>

          );
        }
    }
}

export default BookingItem;
