import React, { Component } from "react";
import BookingItem from './bookingItem.js';
import Axios from 'axios';
import {Grid,Cell,Spinner} from 'react-mdl';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';


//const API_PATH = 'https://www.herrzeller.de/service/createpdf.php';
const API_PATH = 'https://platzbuchung.tckirrberg.de/calendar-service.php';
//const API_PATH = 'http://stefan.local/google_calendar_service/calendar-service.php';
/*
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));
*/
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//const classes = useStyles();


class Calendar extends Component {
    constructor(props) {
        super(props);
        //this.handleChange = this.handleChange.bind(this);

        this.state = {
          calendarData: null,
          snackBarOpen: false,
          snackBarType: "success",
          snackBarMessage: ""
        };

        this.requestData();

        this.openSnackbar = this.openSnackbar.bind(this);
        this.onSnackbarClose = this.onSnackbarClose.bind(this);
    }

    requestData() {
      Axios({
          method: 'get',
          url: API_PATH,
          headers: { 'content-type': 'application/json' },
          /*data: {
              filename: this.getPdfFilename(),
              pdfData: dataToSend
          }*/
      })
      .then(result => {
        console.log(result);
          if(result.data){
              this.setState({
                  calendarData: result.data
              });
          }
      })
      .catch(error => this.setState({ error: error.message }));
    }

    openSnackbar = (type,message) => {
      this.setState({
        snackBarOpen: true,
        snackBarType: type,
        snackBarMessage: message
      });
    }

    onSnackbarClose = (text) => {
      console.log(text);
      this.setState({
        snackBarOpen: false,
        snackBarType: "success",
        snackBarMessage: ""
      });
    }

    render() {
      const getEvents = (dayData) =>{
        let events = [];
        for(let k=0; k<dayData.length; k++){
          let day = dayData[k];
          events.push(<BookingItem serviceCb={this.openSnackbar} id={day['id']} freiFlag={day['frei']} startTime={day['startTime']} endTime={day['endTime']} datum={day['datum']} platz={day['platz']} wt={day['wochentag']} />);
        }
        return (events);
      }

      const getDay = (dayData) =>{
        let events = [];
          return(
            <Cell col={1} style={{minWidth:'157px'}}>
            <div className="header">
            <div className="headerwtag">{dayData[0]['wochentag']}</div>
            <div className="headerdatum">{dayData[0]['datum']}</div>
            </div>
            {getEvents(dayData)}
            </Cell>
          )
        }

      if(this.state.calendarData == null){
        return (<Spinner className="formSpinner" singleColor />)
      }
      else{
        let days = [];
        for(let i=0; i<this.state.calendarData.length; i++){
          days.push(getDay(this.state.calendarData[i]));
        }
        return (
          <>
          <Snackbar open={this.state.snackBarOpen} autoHideDuration={6000} onClose={this.onSnackbarClose}>
            <Alert onClose={this.onSnackbarClose} severity={this.state.snackBarType}>
              {this.state.snackBarMessage}
            </Alert>
          </Snackbar>
          <Grid className="demo-grid-ruler">
          {days}
          </Grid>
          </>
        );
      }

    }
}

export default Calendar;
