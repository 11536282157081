import React from 'react';
import Calendar from './components/calendar.js';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <div style={{width: '100%', margin: 'auto'}}>
<Calendar />
    </div>

    </div>
  );
}

export default App;
